<template>
    <div class="card-with-shadow cursor-pointer flex flex-col justify-between items-center w-full min-w-72 max-w-96">
        <div class="w-full h-60 max-h-60">
            <img src="@/assets/global-data.png" class="rounded w-full h-60 max-h-60 object-cover" alt="Logo" />
        </div>


        <div class="info-row flex justify-between items-center w-full">
            <div class="font-bold ">
                All locations
            </div>
            <div>
                <span class="star-icon">
                    <FontAwesomeIcon :icon="['fas', 'star']" fixed-width />

                </span>
                <span class="">
                    4.2
                </span>
            </div>

        </div>

        <div class="text-sm line-clamp-2 break-word mb-2 w-full ">
            View all your data at company level.
        </div>
        <button class="bg-blue-500 hover:bg-blue-700 text-white w-full font-bold py-2 px-4 rounded" @click="$emit('cardClicked')">
            View
        </button>

    </div>



</template>

<script setup>

</script>

<style scoped lang="scss">
    .card-with-shadow {
        padding: 1rem;
        gap: 0px;
        height: 28rem;
    }


</style>