import { createWebHashHistory, createRouter } from 'vue-router'
import { useAuthStore } from '@/stores/authStore';
import { useProjectSlug } from '@/composables/useProjectSlug';
import Login from '../pages/Login.vue'
import Dashboard from '../pages/Dashboard.vue'
import FeedbackList from '../pages/FeedbackList.vue'
import SavedFeedback from '../pages/SavedFeedback.vue'
import Settings from '../pages/Settings.vue'
import Feedback from '../pages/Feedback.vue'
import Sources from '../pages/Sources.vue';
import Projects from '../pages/Projects.vue';
import PageNotFound from "../pages/PageNotFound.vue"
import Customers from '../pages/Customers.vue';
import Customer from '../pages/Customer.vue';
import Requirements from '../pages/Requirements.vue';
import Requirement from '../pages/Requirement.vue';
const routes = [
  { path: '/', redirect: '/projects' },
  {
    path: '/login',
    component: Login,
    name: "Login",
  },
  {
    path: '/:projectSlug/dashboard',
    component: Dashboard,
    name: "Dashboard",
    meta: { requiresAuth: true,requiresProject: true },
  },
  {
    path: '/projects',
    component: Projects,
    name: "Projects",
    meta: { requiresAuth: true, },
  },
  {
    path: '/:projectSlug/feedback-list',
    component: FeedbackList,
    name: "FeedbackList",
    meta: { requiresAuth: true ,requiresProject: true},
  },
  {
    path: '/:projectSlug/feedback/:id',
    component: Feedback,
    name: "Feedback",
    meta: { requiresAuth: true ,requiresProject: true},
  },
  {
    path: '/:projectSlug/saved-feedback',
    component: SavedFeedback,
    name: "SavedFeedback",
    meta
    : { requiresAuth: true ,requiresProject: true},
  },
  {
    path: '/:projectSlug/sources',
    component: Sources,
    name: "Sources",
    meta: { requiresAuth: true ,requiresProject: true},
  },
  {
    path: '/:projectSlug/settings',
    component: Settings,
    name: "Settings",
    meta: { requiresAuth: true },
  },
  {
    path: '/:projectSlug/customers',
    component: Customers,
    name: "Customers",
    meta: { requiresAuth: true },
  },
  {
    path: '/:projectSlug/customers/:id',
    component: Customer,
    name: "Customer",
    meta: { requiresAuth: true },
  },
  {
    path: '/:projectSlug/requirements',
    component: Requirements,
    name: "requirements",
    meta: { requiresAuth: true },
  },
  {
    path: '/:projectSlug/requirements/:id',
    component: Requirement,
    name: "requirement",
    meta: { requiresAuth: true },
  },

  { path: '/:pathMatch(.*)*', component: PageNotFound },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    await authStore.checkAuth();

    if (!authStore.isAuthenticated) {
      return next({ name: 'Login', query: { redirect: to.fullPath } });
    }
  }
  next();

});

router.afterEach((to) => {
  const { projectSlug } = useProjectSlug();
  // this is on purpose string undefined because we get it from the route param so it is converted to string
  if (to.meta.requiresProject && (projectSlug.value === 'undefined' || projectSlug.value === undefined)) {
    alert("Select a project first");
    router.push({ name: 'Projects' });
  }
});


export default router;