import { defineStore } from 'pinia';
import { requirementsService } from '../services/requirementsService';

export const useRequirementStore = defineStore('requirements', {
  state: () => ({
    requirements: [], //todo(@john) these are not used yet as they need to be key value pairs with arrays .
    expandedRequirements: [], //Requirements that have been fetched individually and carry the proof models
    loading: false,
    error: null
  }),

  getters: {
    getRequirements: (state) => state.requirements,
    isLoading: (state) => state.loading,
    hasError: (state) => state.error !== null
  },

  actions: {
    async fetchRequirements(projectSlug) {
      this.loading = true;
      this.error = null;
      
      try {
        const requirements = await requirementsService.getRequirements(projectSlug);
        return requirements
      } catch (error) {
        this.error = error.message;
        console.error('Error fetching requirements:', error);
      } finally {
        this.loading = false;
      }
    },
    async fetchRequirementById(id) {
      this.loading = true;
      this.error = null;
      
      try {

        let requirement = this.expandedRequirements.find(requirement => requirement.id === id);
        if (requirement) {
          return requirement;
        }
    
        requirement = await requirementsService.getRequirement(id);
        this.expandedRequirements.push(requirement);
        this.loading = false;
        return requirement

      } catch (error) {
        this.error = error.message;
        console.error('Error fetching requirement:', error);
      } finally {
        this.loading = false;
      }
    },

    async createRequirement(requirement) {
      this.loading = true;
      this.error = null;
      
      try {
        const newRequirement = await requirementsService.createRequirement(requirement);
        // Add the new requirement to the existing requirements array
        this.requirements.push(newRequirement);
        return newRequirement;
      } catch (error) {
        this.error = error.message;
        console.error('Error creating requirement:', error);
      } finally {
        this.loading = false;
      }
    },

    clearRequirements() {
      this.requirements = [];
    },

    clearError() {
      this.error = null;
    }
  }
});