import { mapBasicFeedbackListFromBackend } from "./feedbackMapper"
function mapCustomerFromBackend(customer) {
    return {
        id: customer.uuid,
        name: customer.name,
        surname: customer.surname,
        username: customer.username,
        email: customer.email,
        avatar: customer.avatar,
        originalUrl: customer.original_url,
        language: customer.language,
        country: customer.country,
        phone: customer.phone,
        address: customer.address,
    }
}

function mapCustomerToBackend(customer) {
    return {
        uuid: customer.id,
        name: customer.name,
        surname: customer.surname,
        username: customer.username,
        avatar:data.avatar,
        originalUrl:data.original_url,
        email: customer.email,
        language: customer.language,
        country: customer.country,
        phone: customer.phone,
        address: customer.address,
    }
}
function mapCustomerWithFeedbackFromBackend(data) {
    return{
        id:data.uuid,
        name:data.name,
        surname:data.surname,
        username:data.username,
        email:data.email,
        avatar:data.avatar,
        originalUrl:data.original_url,
        language:data.language,
        country:data.country,
        phone:data.phone,
        address:data.address,
        feedback:mapBasicFeedbackListFromBackend(data.feedback),
    }
}
function mapCustomerWithFeedbackListFromBackend(data) {
    return data.map(customer => mapCustomerWithFeedbackFromBackend(customer))
}
export{
    mapCustomerFromBackend,
    mapCustomerToBackend,
    mapCustomerWithFeedbackFromBackend,
    mapCustomerWithFeedbackListFromBackend
}

