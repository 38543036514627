<template>
    <div class="card-with-shadow flex justify-between mb-4">
        <div class="flex items-center gap-4">
            <div class="h-12 w-12">
                <img :src="getOriginLogo(origin)" :alt="origin + ' Logo'" />
            </div>
            <div>
                {{ connector.name }}
            </div>
            <div class="underline">
                {{ connector.app_id }}
            </div>
        </div>
        <div class="flex items-center gap-2">
            <div>
                <span>
                    Last synced at:
                </span>
                <span>
                    {{ getFormattedDate(connector.last_sync) }}
                </span>
            </div>
            <div>
                <FontAwesomeIcon :icon="['fas', 'pencil']" :size="size" fixed-width @click="onEditConnector(connector)" />
            </div>
            <div>
                <FontAwesomeIcon :icon="['fas', 'x']" :size="size" fixed-width @click="onDeleteConnector(connector)" />
            </div>
        </div>
    </div>
</template>

<script setup>
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { getFormattedDate, getOriginLogo } from '../../utils/utils';
    const props = defineProps({
        connector: Object,
        origin: String,
    });
    const emit = defineEmits(['editConnector', 'deleteConnector']);
    const onEditConnector = (connector) => {
        emit('editConnector', connector);
    };
    const onDeleteConnector = (connector) => {
        emit('deleteConnector', connector);
    };
</script>