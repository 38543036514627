<template>
    <div class="zoho-integration-modal-container">
        <Dialog header="Connect Zoho Reviews Source" v-model:visible="visible" class="zoho-modal" :style="{ width: '50vw' }">
            <div class="flex flex-col gap-4 p-4">
                <!-- Integration Steps -->
                <Steps :model="steps" :activeIndex="currentStep" />

                <!-- Step Content -->
                <div class="mt-4">
                    <!-- Authentication Step -->
                    <div v-if="currentStep === 0" class="flex flex-col gap-4">
                        <div class="bg-blue-50 p-4 rounded-lg">
                            <h3 class="font-medium text-blue-800">Setup Instructions:</h3>
                            <ol class="list-decimal ml-4 mt-2 text-blue-700">
                                <li>Go to your Zoho Developer Console</li>
                                <li>Create a new Client ID (Self Client)</li>
                                <li>Add the necessary scopes (ZohoSurvey.survey.READ, ZohoSurvey.responses.READ)</li>
                                <li>Generate your credentials</li>
                            </ol>
                        </div>

                        <div class="flex flex-col gap-2">
                            <label class="text-sm font-medium">Client ID</label>
                            <InputText v-model="zohoConfig.clientId" placeholder="Enter your Zoho Client ID" class="w-full" />
                        </div>

                        <div class="flex flex-col gap-2">
                            <label class="text-sm font-medium">Client Secret</label>
                            <Password v-model="zohoConfig.clientSecret" placeholder="Enter your Zoho Client Secret" toggleMask class="w-full" />
                        </div>

                        <div class="flex flex-col gap-2">
                            <label class="text-sm font-medium">Domain</label>
                            <Dropdown v-model="zohoConfig.domain" :options="domainOptions" optionLabel="name" optionValue="value" placeholder="Select Zoho Domain" class="w-full" />
                        </div>
                    </div>

                    <!-- Survey Selection Step -->
                    <div v-if="currentStep === 1" class="flex flex-col gap-4">
                        <div class="flex flex-col gap-2">
                            <label class="text-sm font-medium">Select Survey</label>
                            <Dropdown v-model="selectedSurvey" :options="availableSurveys" optionLabel="surveyTitle" placeholder="Select a Survey" class="w-full" :loading="loadingSurveys" />
                        </div>
                    </div>

                    <!-- Sync Configuration Step -->
                    <div v-if="currentStep === 2" class="flex flex-col gap-4">
                        <div class="flex flex-col gap-2">
                            <label class="text-sm font-medium">Sync Frequency</label>
                            <Dropdown v-model="syncConfig.frequency" :options="syncFrequencyOptions" optionLabel="name" optionValue="value" placeholder="Select Sync Frequency" class="w-full" />
                        </div>

                        <div class="flex flex-col gap-2">
                            <label class="text-sm font-medium">Historical Data (days)</label>
                            <InputNumber v-model="syncConfig.historicalDays" placeholder="Number of days to fetch" class="w-full" :min="1" :max="365" />
                        </div>
                    </div>
                </div>

                <!-- Navigation Buttons -->
                <div class="flex justify-between mt-4">
                    <Button label="Back" class="p-button-text" @click="previousStep" v-if="currentStep > 0" />
                    <div class="flex gap-2 ml-auto">
                        <Button label="Cancel" class="p-button-text" @click="closeModal" />
                        <Button :label="currentStep === steps.length - 1 ? 'Connect' : 'Next'" @click="handleStepAction" :loading="loading" />
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script setup>
    import { ref, reactive } from 'vue';
    import Dialog from 'primevue/dialog';
    import InputText from 'primevue/inputtext';
    import Password from 'primevue/password';
    import Dropdown from 'primevue/dropdown';
    import Button from 'primevue/button';
    import Steps from 'primevue/steps';
    import InputNumber from 'primevue/inputnumber';
    import { useToast } from 'primevue/usetoast';
    import { connectorsService } from '../../../services/connectorsService';
    import { useProjectSlug } from '../../../composables/useProjectSlug';

    const toast = useToast();
    const { projectSlug } = useProjectSlug();

    const visible = defineModel()
    const loading = ref(false);
    const loadingSurveys = ref(false);
    const currentStep = ref(0);

    // Step configurations
    const steps = [
        { label: 'Authentication' },
        { label: 'Survey Selection' },
        { label: 'Sync Configuration' }
    ];

    // Form data
    const zohoConfig = reactive({
        clientId: '',
        clientSecret: '',
        domain: null
    });

    const syncConfig = reactive({
        frequency: null,
        historicalDays: 30
    });

    const selectedSurvey = ref(null);
    const availableSurveys = ref([]);

    // Options for dropdowns
    const domainOptions = [
        { name: 'United States', value: 'com' },
        { name: 'Europe', value: 'eu' },
        { name: 'India', value: 'in' },
        { name: 'Australia', value: 'com.au' }
    ];

    const syncFrequencyOptions = [
        { name: 'Every hour', value: 60 },
        { name: 'Every 6 hours', value: 360 },
        { name: 'Every 12 hours', value: 720 },
        { name: 'Daily', value: 1440 }
    ];

    // Methods
    const closeModal = () => {
        visible.value = false;
        currentStep.value = 0;
        zohoConfig.clientId = '';
        zohoConfig.clientSecret = '';
        zohoConfig.domain = null;
        selectedSurvey.value = null;
        syncConfig.frequency = null;
        syncConfig.historicalDays = 30;
    };

    const previousStep = () => {
        if (currentStep.value > 0) {
            currentStep.value--;
        }
    };

    const validateAuthStep = () => {
        if (!zohoConfig.clientId || !zohoConfig.clientSecret || !zohoConfig.domain) {
            toast.add({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Please fill in all authentication details',
                life: 3000
            });
            return false;
        }
        return true;
    };

    const validateSurveyStep = () => {
        if (!selectedSurvey.value) {
            toast.add({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Please select a survey',
                life: 3000
            });
            return false;
        }
        return true;
    };

    const validateSyncStep = () => {
        if (!syncConfig.frequency || !syncConfig.historicalDays) {
            toast.add({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Please configure sync settings',
                life: 3000
            });
            return false;
        }
        return true;
    };

    const fetchSurveys = async () => {
        loadingSurveys.value = true;
        try {
            alert("TODO")
            // // Implement your API call to fetch surveys
            // const response = await connectorsService.getZohoSurveys({
            //     projectSlug: projectSlug,
            //     credentials: zohoConfig
            // });
            // availableSurveys.value = response.surveys;
        } catch (error) {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to fetch surveys',
                life: 3000
            });
        } finally {
            loadingSurveys.value = false;
        }
    };

    const handleStepAction = async () => {
        if (loading.value) return;

        // Validate current step
        let isValid = false;
        switch (currentStep.value) {
            case 0:
                isValid = validateAuthStep();
                if (isValid) {
                    loading.value = true;
                    try {
                        await fetchSurveys();
                        currentStep.value++;
                    } catch (error) {
                        toast.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Failed to validate credentials',
                            life: 3000
                        });
                    }
                    loading.value = false;
                }
                break;
            case 1:
                isValid = validateSurveyStep();
                if (isValid) {
                    currentStep.value++;
                }
                break;
            case 2:
                isValid = validateSyncStep();
                if (isValid) {
                    await saveConfiguration();
                }
                break;
        }
    };

    const saveConfiguration = async () => {
        loading.value = true;
        try {
            // await connectorsService.createZohoConnection({
            //     projectSlug: projectSlug,
            //     config: {
            //         ...zohoConfig,
            //         surveyId: selectedSurvey.value.surveyId,
            //         syncFrequency: syncConfig.frequency,
            //         historicalDays: syncConfig.historicalDays
            //     }
            // });
            alert("TODO")

            toast.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Zoho integration configured successfully',
                life: 3000
            });
            closeModal();
        } catch (error) {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to save configuration',
                life: 3000
            });
        } finally {
            loading.value = false;
        }
    };
</script>

<style scoped>
    .zoho-integration-modal-container :deep(.p-dialog-content) {
        padding: 0;
    }

    .zoho-integration-modal-container :deep(.p-password-input) {
        width: 100%;
    }
</style>