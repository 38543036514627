<template>
    <div class="zendesk-integration-modal-container">
        <Dialog header="Connect Zendesk Reviews Source" v-model:visible="visible" class="zendesk-modal" :style="{ width: '50vw' }">
            <div class="flex flex-col gap-4 p-4">
                <!-- Integration Steps -->
                <Steps :model="steps" :activeIndex="currentStep" />

                <!-- Step Content -->
                <div class="mt-4">
                    <!-- Authentication Step -->
                    <div v-if="currentStep === 0" class="flex flex-col gap-4">
                        <div class="bg-blue-50 p-4 rounded-lg">
                            <h3 class="font-medium text-blue-800">Setup Instructions:</h3>
                            <ol class="list-decimal ml-4 mt-2 text-blue-700">
                                <li>Go to Zendesk Admin Center</li>
                                <li>Navigate to Apps and Integrations → APIs → Zendesk API</li>
                                <li>Create a new API token</li>
                                <li>Copy your subdomain and token</li>
                            </ol>
                        </div>

                        <div class="flex flex-col gap-2">
                            <label class="text-sm font-medium">Zendesk Subdomain</label>
                            <div class="flex items-center gap-2">
                                <InputText v-model="zendeskConfig.subdomain" placeholder="your-subdomain" class="w-full" />
                                <span class="text-gray-500">.zendesk.com</span>
                            </div>
                        </div>

                        <div class="flex flex-col gap-2">
                            <label class="text-sm font-medium">Email</label>
                            <InputText v-model="zendeskConfig.email" placeholder="admin@yourcompany.com" type="email" class="w-full" />
                        </div>

                        <div class="flex flex-col gap-2">
                            <label class="text-sm font-medium">API Token</label>
                            <Password v-model="zendeskConfig.apiToken" placeholder="Enter your API token" toggleMask class="w-full" />
                        </div>
                    </div>

                    <!-- Data Source Configuration Step -->
                    <div v-if="currentStep === 1" class="flex flex-col gap-4">
                        <div class="flex flex-col gap-2">
                            <label class="text-sm font-medium">Select Feedback Type</label>
                            <MultiSelect v-model="selectedFeedbackTypes" :options="feedbackTypeOptions" optionLabel="name" optionValue="value" placeholder="Select feedback types to import" class="w-full" />
                        </div>

                        <div class="flex flex-col gap-2">
                            <label class="text-sm font-medium">Custom Fields</label>
                            <MultiSelect v-model="selectedCustomFields" :options="availableCustomFields" optionLabel="name" :loading="loadingCustomFields" placeholder="Select custom fields to import" class="w-full" />
                        </div>
                    </div>

                    <!-- Sync Configuration Step -->
                    <div v-if="currentStep === 2" class="flex flex-col gap-4">
                        <div class="flex flex-col gap-2">
                            <label class="text-sm font-medium">Sync Frequency</label>
                            <Dropdown v-model="syncConfig.frequency" :options="syncFrequencyOptions" optionLabel="name" optionValue="value" placeholder="Select Sync Frequency" class="w-full" />
                        </div>

                        <div class="flex flex-col gap-2">
                            <label class="text-sm font-medium">Historical Data (days)</label>
                            <InputNumber v-model="syncConfig.historicalDays" placeholder="Number of days to fetch" class="w-full" :min="1" :max="365" />
                        </div>

                        <div class="flex items-center gap-2 mt-2">
                            <Checkbox v-model="syncConfig.includeComments" :binary="true" />
                            <label class="text-sm">Include ticket comments</label>
                        </div>
                    </div>
                </div>

                <!-- Navigation Buttons -->
                <div class="flex justify-between mt-4">
                    <Button label="Back" class="p-button-text" @click="previousStep" v-if="currentStep > 0" />
                    <div class="flex gap-2 ml-auto">
                        <Button label="Cancel" class="p-button-text" @click="closeModal" />
                        <Button :label="currentStep === steps.length - 1 ? 'Connect' : 'Next'" @click="handleStepAction" :loading="loading" />
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script setup>
    import { ref, reactive } from 'vue';
    import Dialog from 'primevue/dialog';
    import InputText from 'primevue/inputtext';
    import Password from 'primevue/password';
    import Dropdown from 'primevue/dropdown';
    import MultiSelect from 'primevue/multiselect';
    import Button from 'primevue/button';
    import Steps from 'primevue/steps';
    import InputNumber from 'primevue/inputnumber';
    import Checkbox from 'primevue/checkbox';
    import { useToast } from 'primevue/usetoast';
    import { connectorsService } from '../../../services/connectorsService';
    import { useProjectSlug } from '../../../composables/useProjectSlug';

    const toast = useToast();
    const { projectSlug } = useProjectSlug();

    const visible = defineModel()
    const loading = ref(false);
    const loadingCustomFields = ref(false);
    const currentStep = ref(0);

    // Step configurations
    const steps = [
        { label: 'Authentication' },
        { label: 'Data Source' },
        { label: 'Sync Configuration' }
    ];

    // Form data
    const zendeskConfig = reactive({
        subdomain: '',
        email: '',
        apiToken: ''
    });

    const selectedFeedbackTypes = ref([]);
    const selectedCustomFields = ref([]);
    const availableCustomFields = ref([]);

    const syncConfig = reactive({
        frequency: null,
        historicalDays: 30,
        includeComments: true
    });

    // Options for dropdowns
    const feedbackTypeOptions = [
        { name: 'Tickets', value: 'tickets' },
        { name: 'Customer Satisfaction Surveys', value: 'satisfaction' },
        { name: 'Help Center Comments', value: 'comments' },
        { name: 'Community Posts', value: 'posts' }
    ];

    const syncFrequencyOptions = [
        { name: 'Every hour', value: 60 },
        { name: 'Every 6 hours', value: 360 },
        { name: 'Every 12 hours', value: 720 },
        { name: 'Daily', value: 1440 }
    ];

    // Methods
    const closeModal = () => {
        visible.value = false;
        currentStep.value = 0;
        zendeskConfig.subdomain = '';
        zendeskConfig.email = '';
        zendeskConfig.apiToken = '';
        selectedFeedbackTypes.value = [];
        selectedCustomFields.value = [];
        syncConfig.frequency = null;
        syncConfig.historicalDays = 30;
        syncConfig.includeComments = true;
    };

    const previousStep = () => {
        if (currentStep.value > 0) {
            currentStep.value--;
        }
    };

    const validateAuthStep = () => {
        if (!zendeskConfig.subdomain || !zendeskConfig.email || !zendeskConfig.apiToken) {
            toast.add({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Please fill in all authentication details',
                life: 3000
            });
            return false;
        }
        return true;
    };

    const validateDataSourceStep = () => {
        if (selectedFeedbackTypes.value.length === 0) {
            toast.add({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Please select at least one feedback type',
                life: 3000
            });
            return false;
        }
        return true;
    };

    const validateSyncStep = () => {
        if (!syncConfig.frequency || !syncConfig.historicalDays) {
            toast.add({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Please configure sync settings',
                life: 3000
            });
            return false;
        }
        return true;
    };

    const fetchCustomFields = async () => {
        loadingCustomFields.value = true;
        try {
            // const response = await connectorsService.getZendeskCustomFields({
            //     projectSlug: projectSlug,
            //     credentials: zendeskConfig
            // });
            // availableCustomFields.value = response.customFields;
            alert("TODO")
        } catch (error) {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to fetch custom fields',
                life: 3000
            });
        } finally {
            loadingCustomFields.value = false;
        }
    };

    const handleStepAction = async () => {
        if (loading.value) return;

        let isValid = false;
        switch (currentStep.value) {
            case 0:
                isValid = validateAuthStep();
                if (isValid) {
                    loading.value = true;
                    try {
                        // Verify credentials
                        // await connectorsService.verifyZendeskCredentials({
                        //     projectSlug: projectSlug,
                        //     credentials: zendeskConfig
                        // });
                        // await fetchCustomFields();
                        alert("TODO")
                        currentStep.value++;
                    } catch (error) {
                        toast.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'Failed to validate credentials',
                            life: 3000
                        });
                    }
                    loading.value = false;
                }
                break;
            case 1:
                isValid = validateDataSourceStep();
                if (isValid) {
                    currentStep.value++;
                }
                break;
            case 2:
                isValid = validateSyncStep();
                if (isValid) {
                    await saveConfiguration();
                }
                break;
        }
    };

    const saveConfiguration = async () => {
        loading.value = true;
        try {
            // await connectorsService.createZendeskConnection({
            //     projectSlug: projectSlug,
            //     config: {
            //         ...zendeskConfig,
            //         feedbackTypes: selectedFeedbackTypes.value,
            //         customFields: selectedCustomFields.value,
            //         syncFrequency: syncConfig.frequency,
            //         historicalDays: syncConfig.historicalDays,
            //         includeComments: syncConfig.includeComments
            //     }
            // });
            alert("TODO")
            toast.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Zendesk integration configured successfully',
                life: 3000
            });
            closeModal();
        } catch (error) {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to save configuration',
                life: 3000
            });
        } finally {
            loading.value = false;
        }
    };
</script>

<style scoped>
    .zendesk-integration-modal-container :deep(.p-dialog-content) {
        padding: 0;
    }

    .zendesk-integration-modal-container :deep(.p-password-input) {
        width: 100%;
    }
</style>