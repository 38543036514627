<template>
    <div class="feedback-table-container">
        <Toast />
        <div class="flex align-center justify-between mb-2">
            <div class="count">
                <span class="font-bold">Total Results : {{ totalRecords }}</span>
            </div>
            <div class="tools-container flex align-center gap-4">

                <div class="search-bar">
                    <IconField>
                        <InputIcon @click="onSearch()" style="cursor:pointer;">
                            <FontAwesomeIcon :icon="['fas', 'search']" fixed-width />
                        </InputIcon>
                        <InputText class="search-input" v-model="searchValue" placeholder="Search" @keyup.enter="onSearch()" />
                    </IconField>
                </div>
                <div class="filters">
                    <Drawer v-model:visible="filtersDrawerVisible" header="Select Filters" position="right">
                        <div class="flex justify-between pb-2">
                            <h1> Filters</h1>
                            <div class="underline cursor-pointer" @click="onClearOptions()">Clear options</div>
                        </div>
                        <div class="border-b py-2"> Feedback Type:</div>
                        <div v-for="option of feedbackTypeOptions" :key="option.value" class="flex items-center gap-2 p-1">
                            <Checkbox v-model="selectedFeedbackType" :inputId="option.value" name="type" :value="option.value" />
                            <label :for="option.value">{{ option.label }}</label>
                        </div>
                        <div class="border-b py-2"> Sentiment:</div>
                        <div v-for="option of sentimentOptions" :key="option.value" class="flex items-center gap-2 p-1">
                            <Checkbox v-model="selectedSentiment" :inputId="option.value" name="sentiment" :value="option.value" />
                            <label :for="option.value">{{ option.label }}</label>
                        </div>
                        <div class="border-b py-2"> Priority:</div>
                        <div v-for="option of priorityOptions" :key="option.value" class="flex items-center gap-2 p-1">
                            <Checkbox v-model="selectedPriority" :inputId="option.value" name="priority" :value="option.value" />
                            <label :for="option.value">{{ option.label }}</label>
                        </div>
                        <div class="border-b py-2"> Feedback Language:</div>
                        <div v-for="option of languageOptions" :key="option.value" class="flex items-center gap-2 p-1">
                            <Checkbox v-model="selectedLanguage" :inputId="option.value" name="language" :value="option.value" />
                            <label :for="option.value">
                                <span class="flag-container">
                                    <span :class="`fi fi-${iso639ToIso3166(option.value)}`"></span>
                                </span>
                            </label>
                        </div>
                        <div class="flex justify-between pt-4">
                            <Button class="drawer-button" label="Cancel" @click="filtersDrawerVisible = false" />
                            <Button class="drawer-button" label="Apply Filters" @click="onApplyFilters()" />
                        </div>
                    </Drawer>
                    <Button class="drawer-button" icon="pi pi-filter" @click="filtersDrawerVisible = true" />
                </div>
            </div>
        </div>
        <DataTable :value="displayedFeedbackItems" :rows="rowsPerPage" :rowHover="true">
            <Column field=" id" header="ID">
                <template #body="{ data }">
                    <Skeleton v-if="isLoading" width="100%" height="40px" />
                    <div class="column id-column" v-else>
                        <div v-tooltip.top="data.id">
                            <p>#{{ data.id.toString().slice(0, 8) }}</p>
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="priority" header="Priority">
                <template #body="{ data }">
                    <Skeleton v-if="isLoading" width="100%" height="40px" />
                    <div class="column priority-column" v-else>
                        <FontAwesomeIcon :icon="['fas', 'arrow-right']" size="xl" :class="data.priority === 'high' ? 'high-priority' : data.priority === 'medium' ? 'medium-priority' : 'low-priority'" fixed-width />
                    </div>
                </template>
            </Column>
            <Column field="text" header="Feedback">
                <template #body="{ data }">
                    <Skeleton v-if="isLoading" width="100%" height="40px" />
                    <div class="column feedback-column" @click="router.push(`/${projectSlug}/feedback/${data.id}`)" v-else>
                        <div class="logo-container">
                            <img :src="getOriginLogo(data.sourceDataType)" alt="Origin Logo" />
                        </div>
                        <div v-tooltip="{
                            value: showEnglishOnly ? data.content : data.translatedContent,
                            showDelay: 500, offset: 2,
                        }" class="text-container">
                            <p>{{ showEnglishOnly ? data.translatedContent : data.content }}</p>
                        </div>

                    </div>
                </template>
            </Column>
            <Column field="customer" header="Customer">
                <template #body="{ data }">
                    <Skeleton v-if="isLoading" width="100%" height="40px" />
                    <div class="column customer-column" v-else>
                        <div class="flag-container">
                            <span :class="`fi fi-${iso639ToIso3166(data.language)}`"></span>
                        </div>
                        <div class="text-container">
                            <p>{{ data.customer.username ?? data.customer?.email }}</p>
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="category" header="Category">
                <template #body="{ data }">
                    <Skeleton v-if="isLoading" width="100%" height="40px" />
                    <div class="column category-column" v-else>
                        <Tag :label="data.feedbackType" :value="data.feedbackType" :severity="getSeverity(data.feedbackType)" />
                    </div>
                </template>
            </Column>
            <Column field="hasReply" header="Has Reply">
                <template #body="{ data }">
                    <Skeleton v-if="isLoading" width="100%" height="40px" />
                    <div class="column reply-column" v-else-if="data?.replyContent">
                        <!-- green check mark icon -->
                        <FontAwesomeIcon :icon="['fas', 'check']" size="xl" fixed-width style="color:green" />
                    </div>
                    <div class="column reply-column" v-else>
                        <!-- red cross icon -->
                        <FontAwesomeIcon :icon="['fas', 'times']" size="xl" fixed-width style="color:red" />
                    </div>
                </template>
            </Column>
            <Column field="date" header="Date">
                <template #body="{ data }">
                    <Skeleton v-if="isLoading" width="100%" height="40px" />
                    <div class="column date-column" v-else>
                        <div class="text-container">
                            <p>{{ toLocaleDateString(data.submittedAt) }}</p>
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="saved" header="">
                <template #body="{ data }">
                    <Skeleton v-if="isLoading" width="100%" height="40px" />
                    <div class="column saved-column" v-else>
                        <FontAwesomeIcon :icon="['fas', 'bookmark']" size="xl" :class="data.isLiked ? 'heart-selected' : 'heart-unselected'" @click="onSaveClick(data)" />
                    </div>
                </template>
            </Column>
        </DataTable>
        <Paginator :rows="20" :totalRecords="totalRecords" v-model:first="first" :template="{
            default: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown'
        }"></Paginator>
    </div>
</template>
<script setup>
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import Skeleton from 'primevue/skeleton';
    import Drawer from 'primevue/drawer';
    import Tag from 'primevue/tag';
    import Button from 'primevue/button';
    import IconField from 'primevue/iconfield';
    import InputText from 'primevue/inputtext';
    import InputIcon from 'primevue/inputicon';
    import feedbackService from '@/services/feedbackService';
    import { onMounted, ref, watch, toRefs, computed } from 'vue';
    import { getOriginLogo } from '@/utils/utils';
    import { useRouter } from "vue-router";
    import Paginator from 'primevue/paginator';
    import Toast from 'primevue/toast';
    import { useToast } from 'primevue/usetoast';
    import { iso639ToIso3166 } from '@/utils/utils';
    import Checkbox from 'primevue/checkbox';
    import { useAuthStore } from '@/stores/authStore';
    import { useProjectSlug } from '../../composables/useProjectSlug';
    const authStore = useAuthStore();
    const showEnglishOnly = computed(() => authStore.user.user_settings.show_only_english ?? true)
    const toast = useToast();
    const filtersDrawerVisible = ref(false);
    const emit = defineEmits(['firstChanged', 'searchClicked', 'updateFilters'])
    const { projectSlug } = useProjectSlug();
    const props = defineProps({
        rowsPerPage: {
            type: Number,
            default: 20
        },
        feedbackItems: {
            type: Array,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
        error: {
            type: [String, null],
            required: true,
        },
        totalRecords: {
            type: Number,
            required: true,
        },
    });
    const { feedbackItems } = toRefs(props);
    const searchValue = ref('');

    const displayedFeedbackItems = ref([]);
    const router = useRouter();
    const first = ref(0);

    const feedbackTypeOptions = [
        { label: 'Complaint', value: 'complaint' },
        { label: 'Suggestion', value: 'suggestion' },
        { label: 'Praise', value: 'praise' },
        { label: 'Bug Report', value: 'bug_report' },
        { label: 'Feature Request', value: 'feature_request' },
        { label: 'Question', value: 'question' },
        { label: 'Other', value: 'other' },
    ]
    const selectedFeedbackType = ref([]);

    const sentimentOptions = [
        { label: 'Very Positive', value: 'very_positive' },
        { label: 'Positive', value: 'positive' },
        { label: 'Neutral', value: 'neutral' },
        { label: 'Negative', value: 'negative' },
        { label: 'Very Negative', value: 'very_negative' },
    ]
    const selectedSentiment = ref([]);

    const priorityOptions = [
        { label: 'High', value: 'high' },
        { label: 'Medium', value: 'medium' },
        { label: 'Low', value: 'low' },
    ]
    const selectedPriority = ref([]);

    const languageOptions = [
        { label: 'Greek', value: 'el' },
        { label: 'English', value: 'en' },
        { label: 'Bulgarian', value: 'bg' },
    ]

    const selectedLanguage = ref([]);

    watch(first, () => {
        emit('firstChanged', first.value)
    });
    watch(feedbackItems, () => {
        displayedFeedbackItems.value = feedbackItems.value;
    });
    watch(searchValue, () => {
        // if user erases the search value, we assume he want to view everything again so we emit the event
        if (searchValue.value.length == 0) {
            emit('searchClicked', searchValue.value)

        }
    })
    onMounted(() => {
        displayedFeedbackItems.value = feedbackItems.value;
    })
    function onClearOptions() {
        selectedFeedbackType.value = []
        selectedSentiment.value = []
        selectedPriority.value = []
        selectedLanguage.value = []
    }
    function onApplyFilters() {
        const filters = {
            feedbackType: selectedFeedbackType.value,
            sentiment: selectedSentiment.value,
            priority: selectedPriority.value,
            language: selectedLanguage.value
        }
        emit('updateFilters', filters)
        filtersDrawerVisible.value = false
    }
    function onSearch() {
        emit('searchClicked', searchValue.value)
    }
    async function onSaveClick(data) {
        if (data.isLiked === true) {
            try {
                let result = await feedbackService.unlikeFeedback(data.id);
                displayedFeedbackItems.value.find(item => item.id === data.id).isLiked = false
                toast.add({ severity: 'success', summary: 'Success Message', detail: 'Feedback unliked successfully', life: 2000 });

            } catch (error) {
                toast.add({ severity: 'error', summary: 'Error Message', detail: 'Error unliking feedback', life: 2000 });
            }
        } else {

            try {
                let result = await feedbackService.likeFeedback(data.id);
                displayedFeedbackItems.value.find(item => item.id === data.id).isLiked = true
                toast.add({ severity: 'success', summary: 'Success Message', detail: 'Feedback liked successfully', life: 2000 });

            } catch (error) {
                toast.add({ severity: 'error', summary: 'Error Message', detail: 'Error liking feedback', life: 2000 });

            }
        }
    }


    function toLocaleDateString(date) {
        return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        });
    }
    function getSeverity(category) {
        switch (category) {
            case 'praise':
                return 'success';
            case 'question':
                return 'warn';
            case 'suggestion':
                return 'info';
            case 'complaint':
                return 'danger';
            case 'bugReport':
                return 'danger';
            default:
                return 'secondary';
        }
    }
</script>


<style scoped lang="scss">

    .feedback-table-container {
        .drawer-button {
            height: 42px;
        }

        .column {
            display: flex;
            align-items: center;
            height: 50px;
            cursor: default;

        }

        .id-column {
            min-width: 60px;
        }

        .priority-column {
            justify-content: center;

            .high-priority {
                color: var(--red-color);
                rotate: -45deg;
            }

            .medium-priority {
                color: var(--yellow-color);
            }

            .low-priority {
                color: var(--green-color);
                rotate: 45deg;
            }
        }

        .feedback-column {
            display: flex;
            justify-content: space-between;
            gap: 8px;
            width: 100%;
            cursor: pointer;

            .logo-container {
                width: 40px;
                height: 40px;
                display: flex;
                flex-shrink: 0;

                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    border: 1px solid var(--border-color);
                    object-fit: contain;
                    padding: 1px;
                }
            }



            .text-container {
                width: 100%;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        .customer-column {
            width: 300px;
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;
            gap: 8px;

            .text-container {
                width: 272px;
                max-width: 272px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .flag-container {
                width: 20px;

            }
        }

        .date-column {
            min-width: 100px;
        }

        .saved-column {
            cursor: pointer;

            .heart-selected {
                color: var(--red-color);
            }

            .heart-unselected {
                color: var(--icons-color)
            }

        }
    }



</style>