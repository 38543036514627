<template>
    <div class="intercom-integration-modal-container">
        <Dialog header="Connect Intercom Reviews Source" v-model:visible="visible" class="intercom-modal" :style="{ width: '50vw' }">
            <div class="flex flex-col gap-4 p-4">
                <!-- Integration Steps -->
                <Steps :model="steps" :activeIndex="currentStep" />

                <!-- Step Content -->
                <div class="mt-4">
                    <!-- Authentication Step -->
                    <div v-if="currentStep === 0" class="flex flex-col gap-4">
                        <div class="bg-blue-50 p-4 rounded-lg">
                            <h3 class="font-medium text-blue-800">Setup Instructions:</h3>
                            <ol class="list-decimal ml-4 mt-2 text-blue-700">
                                <li>Go to your Intercom Developer Hub</li>
                                <li>Create a new App or use existing one</li>
                                <li>Navigate to Authentication → Access tokens</li>
                                <li>Generate an Access Token with these permissions:
                                    <ul class="list-disc ml-6 mt-1">
                                        <li>Read conversations</li>
                                        <li>Read users</li>
                                    </ul>
                                </li>
                            </ol>
                        </div>

                        <div class="flex flex-col gap-2">
                            <label class="text-sm font-medium">Access Token</label>
                            <Password v-model="intercomConfig.accessToken" placeholder="Enter your Intercom Access Token" toggleMask class="w-full" />
                        </div>
                    </div>

                    <!-- Conversation Filter Step -->
                    <div v-if="currentStep === 1" class="flex flex-col gap-4">
                        <div class="bg-gray-50 p-4 rounded-lg">
                            <h3 class="font-medium">Configure Conversation Filters</h3>
                            <p class="text-sm text-gray-600 mt-1">
                                Specify which conversations should be imported as feedback
                            </p>
                        </div>

                        <div class="flex flex-col gap-2">
                            <label class="text-sm font-medium">Tags Filter</label>
                            <MultiSelect v-model="intercomConfig.tags" :options="availableTags" optionLabel="name" placeholder="Select tags to filter conversations" class="w-full" :loading="loadingTags" display="chip" />
                            <small class="text-gray-500">
                                Only conversations with selected tags will be imported
                            </small>
                        </div>

                        <div class="flex flex-col gap-2">
                            <label class="text-sm font-medium">Conversation State</label>
                            <MultiSelect v-model="intercomConfig.states" :options="conversationStates" optionLabel="name" optionValue="value" placeholder="Select conversation states" class="w-full" display="chip" />
                        </div>
                    </div>

                    <!-- Sync Configuration Step -->
                    <div v-if="currentStep === 2" class="flex flex-col gap-4">
                        <div class="flex flex-col gap-2">
                            <label class="text-sm font-medium">Sync Frequency</label>
                            <Dropdown v-model="syncConfig.frequency" :options="syncFrequencyOptions" optionLabel="name" optionValue="value" placeholder="Select Sync Frequency" class="w-full" />
                        </div>

                        <div class="flex flex-col gap-2">
                            <label class="text-sm font-medium">Historical Data (days)</label>
                            <InputNumber v-model="syncConfig.historicalDays" placeholder="Number of days to fetch" class="w-full" :min="1" :max="365" />
                        </div>

                        <div class="flex items-center gap-2 mt-2">
                            <Checkbox v-model="syncConfig.includeResolved" :binary="true" />
                            <label class="text-sm">Include resolved conversations</label>
                        </div>
                    </div>
                </div>

                <!-- Navigation Buttons -->
                <div class="flex justify-between mt-4">
                    <Button label="Back" class="p-button-text" @click="previousStep" v-if="currentStep > 0" />
                    <div class="flex gap-2 ml-auto">
                        <Button label="Cancel" class="p-button-text" @click="closeModal" />
                        <Button :label="currentStep === steps.length - 1 ? 'Connect' : 'Next'" @click="handleStepAction" :loading="loading" />
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import Dialog from 'primevue/dialog';
    import Password from 'primevue/password';
    import Dropdown from 'primevue/dropdown';
    import MultiSelect from 'primevue/multiselect';
    import Button from 'primevue/button';
    import Steps from 'primevue/steps';
    import InputNumber from 'primevue/inputnumber';
    import Checkbox from 'primevue/checkbox';
    import { useToast } from 'primevue/usetoast';
    import { connectorsService } from '../../../services/connectorsService';
    import { useProjectSlug } from '../../../composables/useProjectSlug';

    const toast = useToast();
    const { projectSlug } = useProjectSlug();

    const visible = defineModel();
    const loading = ref(false);
    const loadingTags = ref(false);
    const currentStep = ref(0);

    // Step configurations
    const steps = [
        { label: 'Authentication' },
        { label: 'Conversation Filters' },
        { label: 'Sync Configuration' }
    ];

    // Form data
    const intercomConfig = reactive({
        accessToken: '',
        tags: [],
        states: ['open', 'closed']
    });

    const syncConfig = reactive({
        frequency: null,
        historicalDays: 30,
        includeResolved: true
    });

    const availableTags = ref([]);

    const conversationStates = [
        { name: 'Open', value: 'open' },
        { name: 'Closed', value: 'closed' },
        { name: 'Snoozed', value: 'snoozed' }
    ];

    const syncFrequencyOptions = [
        { name: 'Every hour', value: 60 },
        { name: 'Every 6 hours', value: 360 },
        { name: 'Every 12 hours', value: 720 },
        { name: 'Daily', value: 1440 }
    ];

    // Methods
    const fetchTags = async () => {
        loadingTags.value = true;
        try {
            // const response = await connectorsService.getIntercomTags({
            //     projectSlug: projectSlug,
            //     accessToken: intercomConfig.accessToken
            // });
            alert("TODO")
            availableTags.value = response.tags;
        } catch (error) {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to fetch Intercom tags',
                life: 3000
            });
        } finally {
            loadingTags.value = false;
        }
    };

    const validateAuthStep = async () => {
        if (!intercomConfig.accessToken) {
            toast.add({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Please enter your Intercom Access Token',
                life: 3000
            });
            return false;
        }

        loading.value = true;
        try {
            // Verify the access token
            // await connectorsService.verifyIntercomToken({
            //     projectSlug: projectSlug,
            //     accessToken: intercomConfig.accessToken
            // });
            alert("TODO")
            return true;
        } catch (error) {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Invalid access token',
                life: 3000
            });
            return false;
        } finally {
            loading.value = false;
        }
    };

    const validateFilterStep = () => {
        if (!intercomConfig.states.length) {
            toast.add({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Please select at least one conversation state',
                life: 3000
            });
            return false;
        }
        return true;
    };

    const validateSyncStep = () => {
        if (!syncConfig.frequency || !syncConfig.historicalDays) {
            toast.add({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Please configure sync settings',
                life: 3000
            });
            return false;
        }
        return true;
    };

    const closeModal = () => {
        visible.value = false;
        currentStep.value = 0;
        intercomConfig.accessToken = '';
        intercomConfig.tags = [];
        intercomConfig.states = ['open', 'closed'];
        syncConfig.frequency = null;
        syncConfig.historicalDays = 30;
        syncConfig.includeResolved = true;
    };

    const previousStep = () => {
        if (currentStep.value > 0) {
            currentStep.value--;
        }
    };

    const handleStepAction = async () => {
        if (loading.value) return;

        let isValid = false;
        switch (currentStep.value) {
            case 0:
                isValid = await validateAuthStep();
                if (isValid) {
                    await fetchTags();
                    currentStep.value++;
                }
                break;
            case 1:
                isValid = validateFilterStep();
                if (isValid) {
                    currentStep.value++;
                }
                break;
            case 2:
                isValid = validateSyncStep();
                if (isValid) {
                    await saveConfiguration();
                }
                break;
        }
    };

    const saveConfiguration = async () => {
        loading.value = true;
        try {
            // await connectorsService.createIntercomConnection({
            //     projectSlug: projectSlug,
            //     config: {
            //         accessToken: intercomConfig.accessToken,
            //         tags: intercomConfig.tags,
            //         states: intercomConfig.states,
            //         syncFrequency: syncConfig.frequency,
            //         historicalDays: syncConfig.historicalDays,
            //         includeResolved: syncConfig.includeResolved
            //     }
            // });
            alert("TODO")

            toast.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Intercom integration configured successfully',
                life: 3000
            });
            closeModal();
        } catch (error) {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to save configuration',
                life: 3000
            });
        } finally {
            loading.value = false;
        }
    };
</script>

<style scoped>
    .intercom-integration-modal-container :deep(.p-dialog-content) {
        padding: 0;
    }

    .intercom-integration-modal-container :deep(.p-password-input) {
        width: 100%;
    }
</style>