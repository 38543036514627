<template>
    <div class="csv-modal-container">
        <Dialog header="Add CSV Source" v-model:visible="visible" class="csv-modal" :style="{ width: '70vw' }">
            <div class="flex flex-col space-y-4">
                <div class="flex items-center justify-center w-full" v-if="!csvData.length">
                    <label for="csvFile" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
                        <div class="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                            </svg>
                            <p class="mb-2 text-sm text-gray-500">
                                <span class="font-semibold">Click to upload</span> or drag and drop
                            </p>
                            <p class="text-xs text-gray-500">CSV file only</p>
                        </div>
                        <input id="csvFile" type="file" accept=".csv" class="hidden" @change="handleFileUpload" />
                    </label>
                </div>
                <div class="flex" v-else>
                    Assign your columns to our predefined columns available on the dropdown. You don't need to fill all. At a bare minimum you need to fill the feedback content.
                </div>

                <div v-if="csvData.length" class="overflow-x-auto">
                    <table class="min-w-full bg-white border border-gray-300">
                        <thead>
                            <tr>
                                <th v-for="(header, index) in csvData[0]" :key="index" class="px-4 py-2 text-left bg-gray-100 border-b">
                                    <div class="flex flex-col space-y-2">
                                        <span>{{ header }}</span>
                                        <Dropdown v-model="columnMappings[index]" :options="dbColumns" optionLabel="name" optionValue="value" placeholder="Select column" class="w-full" />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, rowIndex) in csvData.slice(1, 6)" :key="rowIndex">
                                <td v-for="(cell, cellIndex) in row" :key="cellIndex" class="px-4 py-2 border-b">
                                    {{ cell }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <template #footer>
                <Button label="Cancel" @click="closeModal" class="p-button-text" />
                <Button label="Upload" @click="uploadCSV" :disabled="!csvData.length || !isValidMapping" />
            </template>
        </Dialog>
    </div>
</template>

<script setup>
    import Dialog from "primevue/dialog";
    import Button from "primevue/button";
    import Dropdown from "primevue/dropdown";
    import { ref, computed, watch } from "vue";
    import { useToast } from "primevue/usetoast";
    import { connectorsService } from "../../../services/connectorsService";
    import { useProjectSlug } from "../../../composables/useProjectSlug";
    const visible = defineModel()
    const csvData = ref([]);
    const toast = useToast();
    const { projectSlug } = useProjectSlug();
    const dbColumns = [
        { name: 'None', value: 'none' },
        { name: 'Feedback Content', value: 'content' },
        { name: 'ID', value: 'id' },
        { name: 'User ID', value: 'user_id' },
        { name: 'Name', value: 'name' },
        { name: 'Surname', value: 'surname' },
        { name: 'Username', value: 'username' },
        { name: 'Email', value: 'email' },
        { name: 'Phone', value: 'phone' },
        { name: 'Title', value: 'title' },
        { name: 'Submitted At', value: 'submitted_at' },
        { name: 'Reply Content', value: 'reply_content' },
        { name: 'Reply Submitted At', value: 'reply_submitted_at' },
        { name: 'Device', value: 'device' },
        { name: 'App Version', value: 'app_version' },
        { name: 'Rating', value: 'rating' }
    ];

    const columnMappings = ref({});

    const isValidMapping = computed(() => {
        const mappedColumns = Object.values(columnMappings.value);
        return mappedColumns.length === csvData.value[0].length &&
            mappedColumns.includes('content')
    });

    const parseCSV = (text) => {
        const result = [];
        let row = [];
        let entry = '';
        let insideQuotes = false;

        for (let i = 0; i < text.length; i++) {
            const char = text[i];

            if (char === '"') {
                if (insideQuotes && text[i + 1] === '"') {
                    entry += '"';
                    i++;
                } else {
                    insideQuotes = !insideQuotes;
                }
            } else if (char === ',' && !insideQuotes) {
                row.push(entry.trim());
                entry = '';
            } else if (char === '\n' && !insideQuotes) {
                row.push(entry.trim());
                result.push(row);
                row = [];
                entry = '';
            } else {
                entry += char;
            }
        }

        if (entry) {
            row.push(entry.trim());
        }
        if (row.length > 0) {
            result.push(row);
        }

        return result;
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type === "text/csv") {
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;
                csvData.value = parseCSV(content).filter(row => row.length > 1 && row.some(cell => cell.trim() !== ''));

                // Initialize column mappings
                columnMappings.value = {};
                csvData.value[0].forEach((_, index) => {
                    columnMappings.value[index] = 'none';
                });
            };
            reader.readAsText(file);
        } else {
            toast.add({
                severity: "error",
                summary: "Error",
                detail: "Please upload a valid CSV file",
                life: 3000,
            });
        }
    };
    watch(visible, () => {
        // Clear fields when modal is closed
        if (visible.value) {
            csvData.value = [];
            columnMappings.value = {};
            csvData.value = [];
        }
    });
    const closeModal = () => {
        visible.value = false;
        csvData.value = [];
        columnMappings.value = {};
        csvData.value = [];
    };
    const convertToCSV = (data) => {
        // Find indices of columns to keep (value not 'none' in the first data row)
        const indicesToKeep = data[0].reduce((acc, value, index) => {
            if (value !== null && value !== undefined && value !== 'none') {
                acc.push(index);
            }
            return acc;
        }, []);

        // Filter the data to keep only the selected columns
        const filteredData = data.map(row =>
            indicesToKeep.map(index => row[index])
        );

        // Function to escape and quote a field if necessary
        const escapeField = (field) => {
            if (field === null || field === undefined) {
                return '';
            }
            field = field.toString();
            if (field.includes('"') || field.includes(',') || field.includes('\n')) {
                // Escape double quotes by doubling them
                field = field.replace(/"/g, '""');
                // Wrap the field in double quotes
                return `"${field}"`;
            }
            return field;
        };

        // Convert to CSV string with proper escaping
        const csvRows = filteredData.map(row =>
            row.map(escapeField).join(',')
        );

        return csvRows.join('\n');
    };

    async function replaceHeaderWithMappings() {
        for (let i = 0; i < csvData.value[0].length; i++) {
            csvData.value[0][i] = columnMappings.value[i]
        }
    }
    async function uploadCSV() {
        await replaceHeaderWithMappings()
        if (!isValidMapping.value) {
            toast.add({
                severity: "danger",
                summary: "Error",
                detail: "Please map at least content to one column!",
                life: 3000,
            });
            return;
        }
        const csvString = convertToCSV(csvData.value);
        const blob = new Blob([csvString], { type: 'text/csv' });
        const file = new File([blob], 'data.csv', { type: 'text/csv' });

        try {
            const response = await connectorsService.uploadCSV(file, projectSlug.value);
            toast.add({
                severity: "success",
                summary: "Success",
                detail: "File uploaded successfully",
                life: 3000,
            });
            closeModal()
        } catch (error) {
            toast.add({
                severity: "danger",
                summary: "Error",
                detail: "Something went wrong while uploading the file. Please try again.",
                life: 3000,
            });
        }
    };
</script>