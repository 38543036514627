<template>
    <div class="dashboard-container">
        <Breadcrumb>
            <template #title>
                <h1>Dashboard</h1>
            </template>
        </Breadcrumb>
        <div class=" card-with-shadow awesome-box flex flex-col gap-2 justify-center items-center mb-8">
            <div class="font-bold text-2xl">Hello {{ firstName }} {{ lastName }}</div>
            <div class="font-medium text-l">Welcome To Your Awesome Cogny Dashboard!</div>
        </div>
        <div class="dashboard-stats-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
            <template v-if="isLoading">
                <Skeleton height="104px" class="mb-8" />
                <Skeleton height="104px" class="mb-8" />
                <Skeleton height="104px" class="mb-8" />
            </template>
            <div v-for="(card, index) in cards" :key="index" v-else>
                <StatCard class="stat-card" :title="card.title" :value="card.titleValue" :iconBgColor="icons[index].iconBgColor" :iconColor="icons[index].iconColor" :iconName="icons[index].iconName">
                    <template #subtitle>
                        <span :class="card.positiveTrend ? 'change-positive' : 'change-negative'">
                            <span>{{ card.subtitleValue }} </span> {{ card.subtitle }}
                        </span>
                        <span>
                            <FontAwesomeIcon :icon="['fas', 'arrow-right']" size="lg" :class="card.positiveTrend ? 'arrow-positive' : 'arrow-negative'" fixed-width />
                        </span>
                    </template>
                </StatCard>
            </div>

        </div>
        <div class="grid grid-cols-1 lg:grid-cols-1 gap-4 mb-8">
            <LineChart :chartData="sentimentChartData" />
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-8">
            <CustomerSegmentFeedbackTable :feedbackItems="positiveFeedback" title="Positive Feedback" color="green" />
            <CustomerSegmentFeedbackTable :feedbackItems="negativeFeedback" title="Negative Feedback" color="red" />
            <CustomerSegmentFeedbackTable :feedbackItems="neutralFeedback" title="Neutral Feedback" color="orange" />
        </div>

    </div>

</template>

<script setup>
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import StatCard from '../components/Dashboard/StatCard.vue';
    import LineChart from '../components/Dashboard/LineChart.vue';
    import Skeleton from 'primevue/skeleton';
    import CustomerSegmentFeedbackTable from '../components/Dashboard/CustomerSegmentFeedbackTable.vue';
    import { useProjectSlug } from '../composables/useProjectSlug';
    import { useAuthStore } from '@/stores/authStore';
    import { computed, onMounted, ref } from 'vue';
    import { numberToMonth } from '../utils/utils';
    import { dataAggregatorService } from '../services/dataAggregatorService';
    const { projectSlug } = useProjectSlug();
    const authStore = useAuthStore()
    const firstName = ref('')
    const lastName = ref('')
    const cards = ref([])
    const sentimentDataPoints = ref([])
    const positiveFeedback = ref([])
    const negativeFeedback = ref([])
    const neutralFeedback = ref([])
    const sentimentChartData = computed(() => getSentimentChartData())
    const isLoading = ref(true)
    const icons = [
        {
            iconBgColor: "#3939ff",
            iconColor: "#ffffff",
            iconName: "layer-group"
        },
        {
            iconBgColor: "#00045a",
            iconColor: "#ffffff",
            iconName: "face-smile"
        },
        {
            iconBgColor: "#3939ff",
            iconColor: "#ffffff",
            iconName: "lightbulb"
        }
    ];
    onMounted(async () => {
        if (projectSlug.value === 'undefined' || projectSlug.value === undefined) {
            routerKey.push('/projects')
        }
        firstName.value = authStore.user.user.first_name
        lastName.value = authStore.user.user.last_name
        const dashboardData = await dataAggregatorService.getDashboardData(projectSlug.value)
        cards.value = dashboardData.cards
        sentimentDataPoints.value = dashboardData.sentimentDataPoints
        positiveFeedback.value = dashboardData.positiveFeedback
        negativeFeedback.value = dashboardData.negativeFeedback
        neutralFeedback.value = dashboardData.neutralFeedback
        isLoading.value = false
    })


    function getSentimentChartData() {
        const labels = sentimentDataPoints.value.map((item) => item.date)
        const datasets = [{ label: 'Positive Sentiment', data: sentimentDataPoints.value.map(item => item.totalPositive), borderColor: 'green' },
        { label: 'Neutral Sentiment', data: sentimentDataPoints.value.map(item => item.totalNeutral), borderColor: 'orange' },
        { label: 'Negative Sentiment', data: sentimentDataPoints.value.map(item => item.totalNegative), borderColor: 'red' }
        ]
        return {
            labels: labels,
            datasets: datasets
        }
    }

</script>

<style scoped lang="scss">
    .arrow-positive {
        color: var(--green-color);
        transform: rotate(-45deg);
    }

    .arrow-negative {
        color: var(--red-color);
        transform: rotate(45deg);
    }

    .change-positive {
        color: var(--green-color);
    }

    .change-negative {
        color: var(--red-color);
    }



</style>