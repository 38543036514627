<template>
    <div class="requirements-container">
        <div class="top-container">
            <Breadcrumb>
                <template #title>
                    <h1>Requirement Details</h1>
                </template>
            </Breadcrumb>
        </div>
        <div class="mb-4" v-for="requirement in requirements" :key="requirement.id">
            <div class="" @click="router.push(`/${projectSlug}/requirements/${requirement.id}`)">
                <RequirementCard :requirement="requirement" />
            </div>
        </div>
    </div>
</template>

<script setup>
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import { computed, onMounted, ref } from 'vue'
    import { useRequirementStore } from '../stores/requirementsStore';
    import { useProjectSlug } from '../composables/useProjectSlug';
    import { useRouter } from 'vue-router';
    import RequirementCard from '../components/Requirements/RequirementCard.vue';
    const router = useRouter();
    const { projectSlug } = useProjectSlug();
    const requirementsStore = useRequirementStore();
    const requirements = ref(null);
    onMounted(async () => {
        requirements.value = await requirementsStore.fetchRequirements(projectSlug.value);
    });

</script>

<style scoped lang="scss"></style>