<template>
    <div class="feedback-content">

        <div class="feedback-details">
            <div class="origin-rating-priority-container">
                <div class="logo-container">
                    <img :src="getOriginLogo(feedback.sourceDataType)" alt="Origin Logo" />
                </div>
                <Rating v-model="feedback.sourceData.rating" readonly />
                <!-- <Tag :value="'Priority: ' + feedback.feedbackAnalysis?.priority" :severity="getSeverityFromPriority(feedback.feedbackAnalysis.priority)" /> -->
                <!-- <FontAwesomeIcon :icon="['fas', 'language']" :class="showOriginalText ? 'translate-icon' : 'translate-icon-enabled'" size="xl" @click="showOriginalText = !showOriginalText" /> -->

            </div>
            <div class="date-saved-container">
                <p>{{ getFormattedDate(feedback.submittedAt) }}</p>
                <FontAwesomeIcon :icon="['fas', 'bookmark']" size="xl" :class="isLiked ? 'heart-selected' : 'heart-unselected'" @click="emit('saveClicked')" />
            </div>
        </div>
        <div class="feedback-text border-x-1">
            <h4>Customer Text</h4>
            <div v-html="displayedText" />
        </div>
        <div class="reply-text mb-4">
            <h4> Reply Text </h4>
            <p>{{ feedback.replyContent ?? 'You have not replied to this review.' }}</p>
        </div>


    </div>

</template>


<script setup>
    import { ref, onMounted, computed } from 'vue';
    import Rating from 'primevue/rating';
    import Button from 'primevue/button';
    import Tag from 'primevue/tag';
    import { getOriginLogo, getSeverityFromPriority, getFormattedDate } from '../../utils/utils';

    const props = defineProps({
        feedback: {
            type: Object,
            required: true,
        },
        isLiked: {
            type: Boolean,
            required: true,
        },
    })
    const showOriginalText = ref(false);
    const emit = defineEmits(['saveClicked'])
    const highlightedContent = ref('');
    const displayedText = computed(() => {
        // return showOriginalText.value ? props.feedback.content : highlightedContent.value
        return highlightedContent.value
    });

    onMounted(() => {
        highlightedContent.value = props.feedback.content;

        for (let i = 0; i < props.feedback.feedbackAnalysis.keyPhrases.length; i++) {
            const keyPhrase = props.feedback.feedbackAnalysis.keyPhrases[i];
            highlightText(keyPhrase.text, keyPhrase.sentiment);
        }
    });

    function highlightText(text, sentiment) {
        // we want to surround matching text with spans with a class of highlight
        if (sentiment < 0) {
            highlightedContent.value = highlightedContent.value.replace(text, `<span style="border-bottom:4px solid #e61717;margin-right:2px;border-radius:2px">${text}</span>`);
        }
        else if (sentiment < 50) {
            highlightedContent.value = highlightedContent.value.replace(text, `<span style="border-bottom:4px solid #f2b007;margin-right:2px;border-radius:2px">${text}</span>`);
        }
        else {
            highlightedContent.value = highlightedContent.value.replace(text, `<span style="border-bottom:4px solid #11f04c;margin-right:2px;border-radius:2px">${text}</span>`);
        }
    }



</script>

<style scoped lang="scss">
    .feedback-content {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .feedback-details {
            display: flex;
            justify-content: space-between;

            .origin-rating-priority-container {
                display: flex;
                align-items: center;
                gap: 20px;

                .logo-container {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    flex-shrink: 0;

                    img {
                        height: 100%;
                        width: 100%;
                        border-radius: 50%;
                        border: 1px solid var(--border-color);
                        object-fit: contain;
                        padding: 1px;
                    }
                }
            }

            .date-saved-container {
                display: flex;
                align-items: center;
                gap: 20px;

                .heart-selected {
                    color: red;
                    cursor: pointer;
                }

                .heart-unselected {
                    cursor: pointer;
                }
            }

            .p-rating {
                --p-rating-icon-color: gold;
                --p-rating-icon-active-color: gold;

            }
        }

        .feedback-text {
            min-height: 10vh;
            overflow: auto;
        }

        .reply-text {
            min-height: 5vh;
            overflow: auto;
        }

        .translate-icon {
            cursor: pointer;
            color: var(--icons-color);
        }

        .translate-icon-enabled {
            cursor: pointer;
            color: var(--primary-color);
        }
    }
</style>