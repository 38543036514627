import { map } from "d3";

function mapRequirementProofFromBackend(data) {
    return {
        id:data.id,
        note: data.note,
        media: data.media,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
    }
}
function mapRequirementProofListFromBackend(data) {
    return data.map(proof => mapRequirementProofFromBackend(proof));
}

function mapRequirementFromBackend(data) {
    return {
        id:data.id,
        name: data.name,
        description: data.description,
        latestProof:data.latest_proof?mapRequirementProofFromBackend(data.latest_proof):null,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
    }
}

function mapExpandedRequirementFromBackend(data) {
    return {
        id:data.id,
        name: data.name,
        description: data.description,
        proofs: mapRequirementProofListFromBackend(data.proofs),
        createdAt: data.created_at,
        updatedAt: data.updated_at,
    }
}
function mapRequirementListFromBackend(data) {
    return data.map(requirement => mapRequirementFromBackend(requirement));
}

export { mapRequirementFromBackend, mapRequirementListFromBackend, mapRequirementProofFromBackend,mapExpandedRequirementFromBackend };