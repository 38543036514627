<template>
    <div class="customer-segment-feedback-table card-with-shadow" :style="{ borderColor: color, borderWidth: '2px', borderRadius: '4px' }">
        <div :style="{ color: color, borderColor: color, borderBottom: '2px' }">
            <h1>{{ title }}</h1>
        </div>
        <DataTable :value="feedbackItems" :rows="10" :rowHover="true" class="cursor-pointer" @row-click="onRowClick($event)">
            <Column field="id" header="ID">
                <template #body="{ data }">
                    <div class="column id-column">
                        <p>#{{ data.id.toString().slice(0, 8) }}</p>
                    </div>
                </template>
            </Column>
            <Column field="content" header="Feedback">
                <template #body="{ data }">
                    <div class="column feedback-column">
                        <p>{{ data.content }}</p>
                    </div>
                </template>
            </Column>
            <Column field="rating" header="Rating">
                <template #body="{ data }">
                    <div class="column rating-column">
                        <p>{{ data?.sourceData?.rating }}</p>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script setup>
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import { useRouter } from 'vue-router';
    import { useProjectSlug } from '../../composables/useProjectSlug';
    const router = useRouter();
    const { projectSlug } = useProjectSlug();
    import { ref } from 'vue'
    const props = defineProps({
        feedbackItems: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        }
    })
    function onRowClick(row) {
        router.push(`/${projectSlug.value}/feedback/${row.data.id}`)
    }
</script>

<style scoped lang="scss"></style>