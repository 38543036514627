<template>
    <div class="google-maps-reviews-modal-container">
        <Dialog header="Add Google Maps Reviews Source" v-model:visible="visible" class="csv-modal" :style="{ width: '50vw' }">
            <div class="flex flex-col gap-4 p-4">
                <!-- Search Input with Autocomplete -->
                <div class="flex flex-col gap-2">
                    <label class="text-sm font-medium">Search for a place</label>
                    <div class="relative">
                        <InputText v-model="searchQuery" placeholder="Enter a location name" class="w-full p-2" @input="handleSearchInput" @focus="showPredictions = true" />
                        <!-- Autocomplete Dropdown -->
                        <div v-if="showPredictions && predictions.length > 0" class="absolute z-50 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-y-auto">
                            <div v-for="prediction in predictions" :key="prediction.place_id" class="p-3 hover:bg-gray-100 cursor-pointer" @click="selectPlace(prediction)">
                                <div class="font-medium">{{ prediction.structured_formatting.main_text }}</div>
                                <div class="text-sm text-gray-600">{{ prediction.structured_formatting.secondary_text }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Map Container -->
                <div id="map" class="w-full h-[300px] rounded-lg border border-gray-200"></div>

                <!-- Selected Place Info -->
                <div v-if="selectedPlace" class="bg-gray-50 p-4 rounded-lg">
                    <h3 class="font-medium">Selected Place:</h3>
                    <p>Name: {{ selectedPlace.name }}</p>
                    <p>Address: {{ selectedPlace.formatted_address }}</p>
                    <p>Place ID: {{ selectedPlace.place_id }}</p>
                </div>

                <!-- Footer Actions -->
                <div class="flex justify-end gap-2 mt-4">
                    <Button label="Cancel" class="p-button-text" @click="closeModal" />
                    <Button label="Add Source" @click="addSource" :disabled="!selectedPlace" />
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script setup>
    import InputText from "primevue/inputtext";
    import Button from "primevue/button";
    import Dialog from "primevue/dialog"
    import { ref, onMounted, watch, onUnmounted } from "vue"
    import { useToast } from 'primevue/usetoast';
    import { connectorsService } from "../../../services/connectorsService";
    import { useProjectSlug } from "../../../composables/useProjectSlug";
    import { Loader } from "@googlemaps/js-api-loader";

    const toast = useToast();
    const { projectSlug } = useProjectSlug();

    const visible = defineModel();
    const searchQuery = ref('');
    const selectedPlace = ref(null);
    const predictions = ref([]);
    const showPredictions = ref(false);

    let map = null;
    let placesService = null;
    let autocompleteService = null;
    let marker = null;
    const initializeMap = async () => {

        const loader = new Loader({
            apiKey: import.meta.env.VITE_GOOGLE_PLACES_API_KEY,
            version: "weekly",
            libraries: ["places"]
        });

        try {
            const google = await loader.load();

            // Initialize the map
            const center = { lat: 37.971083, lng: 23.725834 };
            map = new google.maps.Map(document.getElementById("map"), {
                center,
                zoom: 13
            });

            // Initialize services
            placesService = new google.maps.places.PlacesService(map);
            autocompleteService = new google.maps.places.AutocompleteService();

            // Add event listener for clicking outside the prediction box
            document.addEventListener('click', handleClickOutside);

        } catch (error) {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to load Google Maps: ' + error,
                life: 3000
            });
        }
    };

    // Handle search input for autocomplete
    const handleSearchInput = async () => {
        if (!autocompleteService || !searchQuery.value) {
            predictions.value = [];
            return;
        }

        try {
            const response = await autocompleteService.getPlacePredictions({
                input: searchQuery.value,
                types: ['establishment'], // You can adjust these types based on your needs
            });

            predictions.value = response.predictions;
            showPredictions.value = true;
        } catch (error) {
            console.error('Error fetching predictions:', error);
            predictions.value = [];
        }
    };

    // Select place from autocomplete
    const selectPlace = (prediction) => {
        placesService.getDetails(
            {
                placeId: prediction.place_id,
                fields: ['name', 'geometry', 'formatted_address', 'place_id']
            },
            (place, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    selectedPlace.value = place;
                    searchQuery.value = place.name;
                    showPredictions.value = false;

                    // Center map on selected place
                    map.setCenter(place.geometry.location);
                    map.setZoom(15);

                    // Remove existing marker if any
                    if (marker) {
                        marker.setMap(null);
                    }

                    // Add new marker
                    marker = new google.maps.Marker({
                        map,
                        position: place.geometry.location,
                        title: place.name
                    });
                }
            }
        );
    };

    const handleClickOutside = (event) => {
        if (!event.target.closest('.relative')) {
            showPredictions.value = false;
        }
    };

    const closeModal = () => {
        visible.value = false;
        selectedPlace.value = null;
        searchQuery.value = '';
        predictions.value = [];
        showPredictions.value = false;
        if (marker) {
            marker.setMap(null);
        }
    };

    const addSource = async () => {
        if (!selectedPlace.value) return;
        try {
            await connectorsService.addGoogleMapsReviewsConnector({
                project_slug: projectSlug.value,
                place_id: selectedPlace.value.place_id,
                formatted_address: selectedPlace.value.formatted_address,
                name: selectedPlace.value.name,
            });

            toast.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Google Maps source added successfully',
                life: 3000
            });

            closeModal();
        } catch (error) {
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to add Google Maps source',
                life: 3000
            });
        }
    };
    watch(visible, () => {
        if (visible.value === true) {
            initializeMap()
        }
    })


    onUnmounted(() => {
        document.removeEventListener('click', handleClickOutside);
    });
</script>

<style scoped>
    .google-maps-reviews-modal-container :deep(.p-dialog-content) {
        padding: 0;
    }
</style>