import { defineStore } from 'pinia';
import { customersService } from '../services/customersService';
export const useCustomerStore = defineStore('customers', {
  state: () => ({
    customers: [],
    loading: false,
    error: null
  }),

  getters: {
    getCustomers: (state) => state.customers,
    isLoading: (state) => state.loading,
    hasError: (state) => state.error !== null
  },

  actions: {
    async fetchCustomersWithMultipleFeedback() {
      this.loading = true;
      this.error = null;
      
      try {
        // If the customers are already loaded, return immediately to avoid unnecessary API calls
        if (this.customers.length > 0) {
          return;
        }
        const customers = await customersService.getCustomersWithMultipleFeedback();
        this.customers = customers;
      } catch (error) {
        this.error = error.message;
        console.error('Error fetching customers:', error);
      } finally {
        this.loading = false;
      }
    },
    async fetchCustomerById(id) {
      this.loading = true;
      this.error = null;
      
      try {
        // If the customer is already loaded, return immediately to avoid unnecessary API calls
        let customer = this.customers.find(customer => customer.id === id);
        if (customer) {
          return customer;
        }
    
        customer = await customersService.getCustomerById(id);
        this.loading = false;
        return customer

      } catch (error) {
        this.error = error.message;
        console.error('Error fetching customer:', error);
      } finally {
        this.loading = false;

      }
    },


    clearCustomers() {
      this.customers = [];
    },

    clearError() {
      this.error = null;
    }
  }
});