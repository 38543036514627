    <template>
        <div class="login-container flex justify-center items-center">
            <form @submit.prevent="handleLogin">
                <div class="background"></div>
                <div class="card-with-shadow flex flex-col rounded-t-lg  w-96">
                    <div class="flex justify-center mb-2">
                        <img src="@/assets/cogny-blue-horizontal.png" class="h-10" alt="Logo" />
                    </div>
                    <div class="text-xl text-slate-600 mb-4 text-center">Login to your Cogny account</div>

                    <div class="mb-8">
                        <div class="p-field flex flex-col gap-1 mb-4">
                            <label for="username">Username:</label>
                            <InputText id="username" v-model="username" placeholder="Enter your username" required />
                        </div>
                        <div class="p-field flex flex-col gap-1">
                            <label for="password">Password:</label>
                            <Password v-model="password" placeholder="Enter your password" toggleMask :feedback="false" />
                        </div>
                    </div>
                    <Button label="Login" type="submit" :isLoading="isLoading" class="login-button mb-6" />
                    <div class="">
                        <Message v-if="errorMessage" severity="error" class="error-message">
                            {{ errorMessage }}
                        </Message>
                    </div>
                </div>
            </form>

        </div>
    </template>


<script setup>
    import { ref, onMounted } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useAuthStore } from '@/stores/authStore';
    import Button from 'primevue/button';
    import Password from 'primevue/password';
    import InputText from 'primevue/inputtext';
    import Message from 'primevue/message';
    const router = useRouter();
    const route = useRoute();
    const authStore = useAuthStore();

    const username = ref('');
    const password = ref('');
    const isLoading = ref(false);
    const errorMessage = ref('');


    const handleLogin = async () => {
        isLoading.value = true;
        errorMessage.value = '';
        try {
            await authStore.login({ username: username.value, password: password.value });
            const redirectPath = route.query.redirect || '/';
            router.push(redirectPath);
        } catch (error) {
            errorMessage.value = 'Login failed. Please check your credentials and try again.';
            console.error('Login failed:', error);
        } finally {
            isLoading.value = false;
        }
    };
</script>

<style scoped lang="scss">
    .p-inputtext {
        width: 100% !important;
        height: 44px;
    }

    .p-password {
        width: 100%;

        :deep(.p-password-input) {
            height: 44px;
            width: 100%;
        }
    }
</style>