import { handleApiError, projectAPI } from '../api';
export const projectsService = {
  async getProjects() {
    try{
        const result =await projectAPI.getProjects(); 
        const projects = result.data.data;
        return projects
    }catch(error){
        throw handleApiError(error)
    }
  },
  async createProject(project) {
    try{
      const result = await projectAPI.createProject(project);
      return result.data.data;
    }catch(error){
        throw handleApiError(error)
    }
  },
 
};