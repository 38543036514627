<template>
    <div class="projects-container">
        <Breadcrumb>
            <template #title>
                <h1>Projects</h1>
            </template>
        </Breadcrumb>
        <div class="card-with-shadow min-h-screen">
            <div class="flex justify-between items-center mb-4">
                <h2>
                    Manage all your locations here:
                </h2>
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click="createProjectModalVisible = true">
                    Add new location
                </button>
            </div>
            <div v-if="projects.length === 0" class="flex h-96 justify-center items-center">
                <div class="text-center">
                    <h2>You don't have any projects yet.</h2>
                    <p>Create a new project to get started.</p>
                </div>
            </div>
            <div v-else class="projects grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
                <CompanyCard @cardClicked="onAllProjectsSelected" />
                <template v-for="project in projects" :key="project.id">
                    <ProjectCard :project="project" @projectSelected="onProjectClick" />
                </template>
            </div>
        </div>
        <CreateProjectModal v-model="createProjectModalVisible" @createProject="onCreateProject" />
    </div>
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import { projectsService } from '@/services/projectsService';
    import { useRouter } from 'vue-router';
    import ProjectCard from '../components/Projects/ProjectCard.vue';
    import CompanyCard from '../components/Projects/CompanyCard.vue';
    import CreateProjectModal from '../components/Projects/CreateProjectModal.vue';
    import Toast from 'primevue/toast';
    import { useToast } from 'primevue/usetoast';
    const router = useRouter();
    const projects = ref(null);
    const createProjectModalVisible = ref(false)
    const toast = useToast();

    onMounted(async () => {
        projects.value = await projectsService.getProjects();

    })
    function onProjectClick(project) {
        router.push(`/${project.slug}/dashboard`)
    }
    function onAllProjectsSelected() {
        router.push(`/all/dashboard`)
    }
    async function onCreateProject(project) {
        try {
            const result = await projectsService.createProject(project);
            toast.add({ severity: 'success', summary: 'Success Message', detail: 'Project created successfully', life: 2000 });
            createProjectModalVisible.value = false

            projects.value = [...projects.value, result]
        } catch (error) {
            toast.add({ severity: 'error', summary: 'Error Message', detail: 'Project creation failed' + str(error), life: 2000 });
        }
    }

</script>