<template>
    <div class="project-modal-container">
        <Dialog header="Add new branch" v-model:visible="visible" class="project-modal" :style="{ width: '70vw' }">
            <div class="flex flex-col space-y-4">
                <div class="flex flex-col gap-2">
                    <label for="name">Name:</label>
                    <InputText id="name" v-model="project.name" aria-describedby="name-help" />
                    <div class="text-sm text-gray-500">Name must be at least 3 characters long.</div>
                </div>
                <div class="flex flex-col gap-2">
                    <label for="name">Description:</label>
                    <Textarea id="name" v-model="project.description" aria-describedby="description-help" />
                    <div class="text-sm text-gray-500">Description must be at least 3 characters long.</div>

                </div>
                <div class="flex flex-col gap-2">
                    <label for="name">Image URL:</label>
                    <InputText id="name" v-model="project.image" aria-describedby="image-help" />
                    <div class="text-sm text-gray-500">Use image URL from the web or leave blank.</div>

                </div>
            </div>

            <template #footer>
                <Button label="Cancel" @click="closeModal" class="p-button-text" />
                <Button label="Create" @click="$emit('createProject', project)" :disabled="!isSubmitEnabled" />
            </template>
        </Dialog>
    </div>
</template>

<script setup>
    import Dialog from "primevue/dialog";
    import Button from "primevue/button";
    import InputText from "primevue/inputtext";
    import Textarea from 'primevue/textarea';

    import { reactive, computed, watch } from "vue";
    const isSubmitEnabled = computed(() => project.name.length > 3 && project.description.length > 0)
    const emit = defineEmits(['createProject'])
    const visible = defineModel()
    const project = reactive({
        name: '',
        description: '',
        image: ''
    });

    watch(visible, () => {
        // Clear fields when modal is closed
        if (visible.value) {
            project.name = '';
            project.description = '';
            project.image = '';
        }
    });
    const closeModal = () => {
        visible.value = false;
    };
</script>