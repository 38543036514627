<template>
    <div class="customer-review-card-container">
        <Card class="mt-4">
            <template #title>
                <div class="flex align-center gap-2">
                    <div>
                        {{ getFormattedDate(feedback.submittedAt) }} - {{ feedback.project.name }}
                    </div>
                    <div>
                        <FontAwesomeIcon :icon="['fas', 'arrow-right']" class="origin-icon" @click="onOriginClick" v-if="!isLoading" />
                    </div>
                </div>

            </template>
            <template #subtitle>
                <div class="cursor-pointer" @click="onFeedbackClick">
                    {{ feedback.content }}
                </div>
            </template>
            <template #content>
                <img v-if="feedback.project.image" :src="feedback.project.image" :alt="feedback.project.name" class="rounded w-full h-60 max-h-60 object-cover" />
                <img v-else src="@/assets/default-project.png" class="rounded w-full h-60 max-h-60 object-cover" alt="Default Project Image" />

            </template>
        </Card>

    </div>
</template>

<script setup>
    import Card from 'primevue/card';
    import { getFormattedDate } from '../../utils/utils';
    import { useProjectSlug } from '../../composables/useProjectSlug';
    import { useRouter } from 'vue-router';
    const { projectSlug } = useProjectSlug();
    const router = useRouter();
    const props = defineProps({
        feedback: {
            type: Object,
            required: true
        },
    });
    function onOriginClick() {
        window.open(props.feedback.source.origin, '_blank');
    }
    function onFeedbackClick() {
        router.push(`/${projectSlug.value}/feedback/${props.feedback.id}`)
    }
</script>

<style scoped lang="scss">

    .origin-icon {
        rotate: -45deg;
        cursor: pointer;
        color: var(--primary-color);
    }
</style>