<template>
    <div>
        <div class="top-container">
            <Breadcrumb>
                <template #title>
                    <h1>Customers</h1>
                </template>
            </Breadcrumb>
        </div>
        <div class="border-bottom p-4" v-for="customer in customers" :key="customer.id" @click="onCustomerClick(customer)">
            <CustomerCard :customer="customer" />
        </div>
    </div>
</template>

<script setup>
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import { computed, onMounted, ref } from 'vue'
    import { useCustomerStore } from '@/stores/customerStore';
    import CustomerCard from '../components/Customers/CustomerCard.vue';
    import { useRouter } from 'vue-router';
    import { useProjectSlug } from '../composables/useProjectSlug';
    const { projectSlug } = useProjectSlug();
    const router = useRouter();
    const customerStore = useCustomerStore();

    onMounted(async () => {
        await customerStore.fetchCustomersWithMultipleFeedback();
    })
    const customers = computed(() => customerStore.getCustomers)
    const props = defineProps({});
    const emit = defineEmits([''])
    function onCustomerClick(customer) {
        router.push(`/${projectSlug.value}/customers/${customer.id}`)
    }
</script>

<style scoped lang="scss"></style>