<template>
    <div class="top-container">
        <div class="flex gap-1 items-center text-l ">
            <BackArrow />
            <slot class="italic" name="title"></slot>
            <span class="text-l border-l-2 border-slate-500 pl-1 capitalize">
                {{ projectSlug }}
            </span>
        </div>
    </div>
</template>

<script setup>
    import BackArrow from './BackArrow.vue';
    import { useProjectSlug } from '../../composables/useProjectSlug';
    const { projectSlug } = useProjectSlug()
</script>

<style scoped lang="scss">

    .top-container {
        display: flex;
        align-items: center;
        padding-bottom: 4px;
        min-height: 34px;

    }
</style>