import { customersAPI,handleApiError } from '@/api';
import { mapCustomerWithFeedbackListFromBackend ,mapCustomerWithFeedbackFromBackend} from '../mappers/customerMapper';
export const customersService = {
  async getCustomersWithMultipleFeedback() {
    try {
      const { data } = await customersAPI.customersWithMultipleFeedback();
      return mapCustomerWithFeedbackListFromBackend(data);
    } catch (error) {
      throw handleApiError(error);
    }
  },
  async getCustomerById(id) {
    try {
      const { data } = await customersAPI.getCustomerById(id);
      return mapCustomerWithFeedbackFromBackend(data);
    } catch (error) {
      throw handleApiError(error);
    }
  },


};