<template>
    <div class="sources-container">
        <Breadcrumb>
            <template #title>
                <h1>Sources </h1>
            </template>
        </Breadcrumb>
        <div class="sources-content card-with-shadow mb-8">
            <h2 class="mb-4">Add sources</h2>
            <h1>
                Contact your admin to enable the necessary sources.
            </h1>
            <!-- <SourceRow source="CSV" description="Import feedback from a CSV file" @addSource="onAddSource" />
            <SourceRow source="Google Maps Reviews" description="Import reviews from Google Maps" @addSource="onAddSource" />
            <SourceRow source="Google Play Reviews" description="Import feedback from Google Play Store" @addSource="onAddSource" />
            <SourceRow source="Zoho" description="Import feedback from Zoho" @addSource="onAddSource" />
            <SourceRow source="Intercom" description="Import feedback from Intercom" @addSource="onAddSource" />
            <SourceRow source="Zendesk" description="Import feedback from Zendesk" @addSource="onAddSource" /> -->


        </div>
        <div class="existing-sources-container card-with-shadow">
            <h2 class="mb-4"> Your sources</h2>
            <div v-for="connector in connectors?.google_maps_connectors" :key="connector.id">
                <SourceCard :connector="connector" :origin="'GoogleMapFeedback'" @editConnector="onEditConnector(connector)" @deleteConnector="onDeleteConnector(connector)" />
            </div>
            <div v-for="connector in connectors?.google_play_store_connectors" :key="connector.id">
                <SourceCard :connector="connector" :origin="'GooglePlayReview'" @editConnector="" @deleteConnector="" />
            </div>
        </div>
        <CSVModal v-model="csvModalVisible" />
        <GooglePlayReviewsModal v-model="googlePlayReviewsVisible" />
        <GoogleMapsReviewsModal v-model="googleMapsReviewsVisible" />
        <ZendeskModal v-model="zendeskModalVisible" />
        <IntercomModal v-model="intercomModalVisible" />
        <ZohoModal v-model="zohoModalVisible" />
    </div>
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import SourceRow from '../components/Sources/SourceRow.vue';
    import CSVModal from '../components/Sources/modals/CSVModal.vue';
    import GooglePlayReviewsModal from '../components/Sources/modals/GooglePlayReviewsModal.vue';
    import GoogleMapsReviewsModal from '../components/Sources/modals/GoogleMapsReviewsModal.vue';
    import ZohoModal from '../components/Sources/modals/ZohoModal.vue';
    import ZendeskModal from '../components/Sources/modals/ZendeskModal.vue';
    import IntercomModal from '../components/Sources/modals/IntercomModal.vue';
    import SourceCard from '../components/Sources/SourceCard.vue';
    import { connectorsService } from '@/services/connectorsService';
    import { useProjectSlug } from '@/composables/useProjectSlug';
    const { projectSlug } = useProjectSlug();
    const connectors = ref(null)
    const csvModalVisible = ref(false)
    const googlePlayReviewsVisible = ref(false)
    const googleMapsReviewsVisible = ref(false)
    const zohoModalVisible = ref(false)
    const intercomModalVisible = ref(false)
    const zendeskModalVisible = ref(false)

    onMounted(async () => {
        connectors.value = await connectorsService.getAllConnectors(projectSlug.value)
    })

    function onAddSource(source) {
        if (source === 'CSV') {
            csvModalVisible.value = true;
        } else if (source === 'Google Play Reviews') {
            googlePlayReviewsVisible.value = true;
        } else if (source == 'Google Maps Reviews') {
            googleMapsReviewsVisible.value = true;
        } else if (source === 'Zendesk') {
            zendeskModalVisible.value = true;
        } else if (source === 'Intercom') {
            intercomModalVisible.value = true;
        }
        else if (source === 'Zoho') {
            zohoModalVisible.value = true;
        }
    }
    function onEditConnector(connector) {
        alert("Edit connector coming soon. Now contact your administrator.")
    }
    function onDeleteConnector(connector) {
        alert("Delete connector coming soon. Now contact your administrator.")
    }


</script>

<style scoped lang="scss">
    .card-with-shadow {
        padding: 1rem;
    }

</style>