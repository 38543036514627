import { handleApiError, requirementsAPI } from '../api';
import { mapRequirementListFromBackend,mapExpandedRequirementFromBackend } from '../mappers/requirementMapper';
export const requirementsService = {
  async getRequirements(projectSlug) {
    try {
     
      const result = await requirementsAPI.getRequirements(projectSlug);
      const requirements = result.data.requirements;
      return mapRequirementListFromBackend(requirements);
    } catch (error) {
      throw handleApiError(error);
    }
  },
  async getRequirement(id){
    try {
      const result = await requirementsAPI.getRequirement(id);
      return mapExpandedRequirementFromBackend(result.data.requirement);
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async createRequirement(requirement) {
    try {
      const result = await requirementsAPI.createRequirement(requirement);
      return result.data; 
    } catch (error) {
      throw handleApiError(error);
    }
  }
};