<template>
    <div class="requirement-card card-with-shadow cursor-pointer">
        <div class="flex justify-between">

            <div class="flex flex-col justify-between">
                <div class="flex flex-col">
                    <div class="text-lg font-bold">
                        <div class="mb-2">Name:</div>
                    </div>

                    <span class="text-xl font-bold " style="color:#00045a">
                        {{ requirement.name }}
                    </span>
                </div>
                <div class="flex flex-col">
                    <div class="text-lg font-bold">
                        <div class="mb-2">Description:</div>
                    </div>
                    {{ requirement.description }}
                </div>
            </div>
            <div class="flex flex-col">
                <div>
                    <div class="mb-2">Last submitted proof:</div>
                    <div v-if="requirement.latestProof">{{ getFormattedDate(requirement?.latestProof?.createdAt) }}</div>
                    <div v-else>-</div>
                </div>
                <div class="cursor-pointer" @click="openModal" v-if="requirement.latestProof">
                    <img v-if="isImage(requirement?.latestProof?.media)" class="w-32 h-32 object-cover rounded" :src="requirement?.latestProof?.media" alt="Proof Media" />
                    <video v-else class="w-32 h-32 object-cover rounded" :src="requirement?.latestProof?.media" autoplay muted loop playsinline>
                        <source :src="requirement?.latestProof?.media" type="video/quicktime">
                    </video>
                </div>
                <div v-else>
                    No media submitted yet.
                </div>


            </div>
        </div>
    </div>
</template>

<script setup>
    import { getFormattedDate } from '../../utils/utils';
    const props = defineProps({
        requirement: {
            type: Object,
            required: true
        }
    });


    const isImage = (url) => {
        return url.match(/\.(jpg|jpeg|png|gif)$/i);
    };

</script>

<style scoped lang="scss"></style>