<template>
    <div class="feedback-container">
        <Toast />
        <Breadcrumb>
            <template #title>
                <h1>Feedback #{{ $route.params.id.toString().slice(0, 8) }} </h1><span> by {{ feedback?.customer?.username ?? feedback.customer?.email }} for {{ feedback?.project?.name }}</span>
                <FontAwesomeIcon :icon="['fas', 'arrow-right']" size="xl" class="origin-icon" @click="onOriginClick" v-if="!isLoading" />
            </template>
        </Breadcrumb>
        <div class="main-container card flex">
            <div class="details">
                <div class="main-card ">
                    <Skeleton v-if="isLoading" width="100%" height="300px" />
                    <div class="grid grid-cols-6 gap-4" v-else>
                        <MainFeedbackDetails class="col-span-4" :feedback="feedback" :isLiked="isLiked" @saveClicked="onSaveClick" />
                        <div class="feedback-analysis-container p-4 bg-white rounded-lg shadow-sm">
                            <h3 class="text-lg font-semibold mb-4">Analysis</h3>

                            <!-- Sentiment -->
                            <div class="analysis-item mb-4">
                                <label class="text-sm text-gray-600 mb-1 block">Sentiment</label>
                                <div class="flex items-center">
                                    <span class="capitalize px-3 py-1 rounded-full text-sm" :class="{
                                        'bg-green-100 text-green-800': feedback.feedbackAnalysis.sentiment === 'very_positive',
                                        'bg-blue-100 text-blue-800': feedback.feedbackAnalysis.sentiment === 'positive',
                                        'bg-yellow-100 text-yellow-800': feedback.feedbackAnalysis.sentiment === 'neutral',
                                        'bg-orange-100 text-orange-800': feedback.feedbackAnalysis.sentiment === 'negative',
                                        'bg-red-100 text-red-800': feedback.feedbackAnalysis.sentiment === 'very_negative',
                                    }">
                                        {{ feedback.feedbackAnalysis.sentiment.replace('_', ' ') }}
                                    </span>
                                </div>
                            </div>

                            <!-- Feedback Type -->
                            <div class="analysis-item mb-4">
                                <label class="text-sm text-gray-600 mb-1 block">Type</label>
                                <div class="flex items-center">
                                    <span class="capitalize px-3 py-1 rounded-full text-sm" :class="{
                                        'bg-purple-100 text-purple-800': feedback.feedbackAnalysis.feedbackType === 'praise',
                                        'bg-red-100 text-red-800': feedback.feedbackAnalysis.feedbackType === 'complaint',
                                        'bg-blue-100 text-blue-800': feedback.feedbackAnalysis.feedbackType === 'suggestion'
                                    }">
                                        {{ feedback.feedbackAnalysis.feedbackType }}
                                    </span>
                                </div>
                            </div>

                            <!-- Priority -->
                            <div class="analysis-item mb-4">
                                <label class="text-sm text-gray-600 mb-1 block">Priority</label>
                                <div class="flex items-center">
                                    <span class="capitalize px-3 py-1 rounded-full text-sm" :class="{
                                        'bg-green-100 text-green-800': feedback.feedbackAnalysis.priority === 'low',
                                        'bg-yellow-100 text-yellow-800': feedback.feedbackAnalysis.priority === 'medium',
                                        'bg-red-100 text-red-800': feedback.feedbackAnalysis.priority === 'high'
                                    }">
                                        {{ feedback.feedbackAnalysis.priority }}
                                    </span>
                                </div>
                            </div>

                            <!-- Key Phrases -->
                            <div class="analysis-item">
                                <label class="text-sm text-gray-600 mb-1 block">Key Phrases</label>
                                <div class="flex flex-wrap gap-2">
                                    <span v-for="(phrase, index) in feedback.feedbackAnalysis.keyPhrases" :key="index" class="bg-gray-100 text-gray-700 px-2 py-1 rounded-full text-xs">
                                        {{ phrase.text }}
                                    </span>
                                </div>
                            </div>

                            <!-- Timestamps -->
                            <div class="analysis-item mt-4 pt-4 border-t border-gray-200">
                                <div class="text-xs text-gray-500">
                                    <div>Created: {{ new Date(feedback.feedbackAnalysis.createdAt).toLocaleString() }}</div>
                                    <div>Updated: {{ new Date(feedback.feedbackAnalysis.updatedAt).toLocaleString() }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-details grid grid-cols-6 gap-4">
                <div class="col-span-4">
                    <Skeleton v-if="isLoading" width="40vw" height="300px" />
                </div>
                <div class="col-span-2 ">
                    <Skeleton v-if="isLoading" width="20vw" height="300px" />
                </div>
            </div>
        </div>
    </div>

</template>

<script setup>
    import MainFeedbackDetails from '../components/Feedback/MainFeedbackDetails.vue';
    import Skeleton from 'primevue/skeleton';
    import { ref, onMounted } from "vue";
    import feedbackService from '../services/feedbackService';
    import { useRoute, useRouter } from 'vue-router'
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import Toast from 'primevue/toast';
    import { useToast } from 'primevue/usetoast';
    const toast = useToast();
    const route = useRoute()
    const router = useRouter()
    // we need to adjust it because the original is in scale 1-10
    const feedbackText = ref('')
    const feedback = ref({});
    const isLiked = ref(false);
    const isLoading = ref(true);
    const error = ref(null);



    function onOriginClick() {
        window.open(feedback.value.source.origin, '_blank');
    }


    async function onSaveClick() {
        if (isLiked.value === true) {
            try {
                let result = await feedbackService.unlikeFeedback(route.params.id);
                isLiked.value = false
                toast.add({ severity: 'success', summary: 'Success Message', detail: 'Feedback unliked successfully', life: 2000 });
            } catch (error) {
                toast.add({ severity: 'error', summary: 'Error Message', detail: 'Error unliking feedback', life: 2000 });
            }
        } else {

            try {
                let result = await feedbackService.likeFeedback(route.params.id);
                isLiked.value = true
                toast.add({ severity: 'success', summary: 'Success Message', detail: 'Feedback liked successfully', life: 2000 });
            } catch (error) {
                ("Key phrase", keyPhrase);
                toast.add({ severity: 'error', summary: 'Error Message', detail: 'Error liking feedback', life: 2000 });

            }
        }
    }
    onMounted(async () => {
        isLoading.value = true;
        error.value = null;
        let id = route.params.id;
        try {
            let result = await feedbackService.getFeedbackItem(id);
            feedback.value = result;
            feedbackText.value = feedback.value.translatedContent;
            isLiked.value = feedback.value.isLiked;

        } catch (err) {
            toast.add({ severity: 'error', summary: 'Error Message', detail: 'Error fetching feedback. Please refresh.', life: 2000 });
            console.error("Error fetching feedback", err)
            error.value = "Failed to fetch feedback. Please try again later."
        } finally {
            isLoading.value = false;
        }
    });


</script>

<style scoped lang="scss">
    canvas {
        margin: 0 auto;
    }

    .feedback-container {
        height: 100%;

        .origin-icon {
            rotate: -45deg;
            cursor: pointer;
            color: var(--primary-color);
        }

        .top-container {
            display: flex;
            align-items: center;
            padding-bottom: 10px;


        }

        .main-container {
            display: flex;
            gap: 20px;

            .details {
                width: 100%;

            }

            .bottom-details {
                height: 100%;

            }
        }
    }


</style>