import { dataAggregatorAPI,handleApiError } from '@/api';
import { mapDashboardDataFromBackend } from "@/mappers/dataAggregatorMapper";

export const dataAggregatorService = {
  async getDashboardData(projectSlug) {
    try {
      const { data } = await dataAggregatorAPI.getDashboardData(projectSlug);
      const results = mapDashboardDataFromBackend(data);
      return results;
    } catch (error) {
      throw handleApiError(error);
    }
  },


};