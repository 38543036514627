import { mapFeedbackPreviewListFromBackend } from "./feedbackMapper";
function mapDashboardDataFromBackend(data){
    return {
        cards: mapCardsFromBackend(data.cards),
        sentimentDataPoints: mapSentimentDataPointsFromBackend(data.sentiment_per_month),
        positiveFeedback: mapFeedbackPreviewListFromBackend(data.positive_feedback),
        negativeFeedback: mapFeedbackPreviewListFromBackend(data.negative_feedback),
        neutralFeedback: mapFeedbackPreviewListFromBackend(data.neutral_feedback),
    }
}
function mapCardsFromBackend(data) {
    return [
            {
                title: 'Total Feedback',
                titleValue: data.total_feedback_stats.total_feedback,
                subtitle: 'New feedback this week',
                subtitleValue: data.total_feedback_stats.new_feedback,
                positiveTrend: data.total_feedback_stats.positive_trend,
            },
            {
                title: 'Positive Feedback this week',
                titleValue: data.satisfied_customers_stats.satisfied_customers_last_week,
                subtitle: 'Average positive feedback per week',
                subtitleValue: data.satisfied_customers_stats.satisfied_customers_per_week,
                positiveTrend: data.satisfied_customers_stats.positive_trend,
            },
             {
                title: 'Negative Feedback this week',
                titleValue: data.unsatisfied_customers_stats.unsatisfied_customers_last_week,
                subtitle: 'Average negative feedback per week',
                subtitleValue: data.unsatisfied_customers_stats.unsatisfied_customers_per_week,
                positiveTrend: data.unsatisfied_customers_stats.positive_trend,

            }
        ]
    };

    function mapSentimentDataPointsFromBackend(data) {
        return data.map((i)=>{return {
            'date':i.date,
            'totalPositive':i.total_positive,
            'totalNeutral':i.total_neutral,
            'totalNegative':i.total_negative}})
    }



export {
    mapDashboardDataFromBackend
}